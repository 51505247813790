<template>
  <section class="content-wrapper p-0 m-0">
    <div class="d-flex my-1 p-1">
      <ListButtonActions id="patrimoine-section" class="ml-auto" />
    </div>
    <div id="patrimoine-section">
      <div class="row p-0 m-0 col-12">
        <analytics-statistics
          v-for="(item, index) in firstSection"
          :key="index"
          :color="item.color"
          :icon="item.icon"
          :title="item.title"
          :value="item.value"
        />
      </div>
      <div class="row p-0 m-0 col-12 mt-2">
        <!-- charts... -->
        <customBarChart
          :options="ForceImpactPatrimoineOptions"
          title="Force de l'impact sur patrimoine"
          class="col-6"
        />
        <customBarChart
          :options="typeImpactObjectOptions"
          title="Type d'impact sur les objets (Patrimoine)"
          class="col-6"
        />
        <customPieChart
          :options="ApplicationsEligibilesClaudificationOptions"
          title="Les applications éligibiles à la claudification"
          class="col-6"
        />
        <customBarChart
          :options="FluxApplicatifsOptions"
          title="Les flux applicatifs"
          class="col-6"
        />
      </div>
      <div class=" row p-0 m-0 col-12 mt-2">
      
        <customGaugeChart
          title="Taux de remplissage applications"
          units=""
          gaugeType="percentage"
          :value="45"
          class="col-md-3 p-1"
        />
        <customGaugeChart
          title="Taux de remplissage processus"
          units=""
          gaugeType="percentage"
          :value="95"
          class="col-md-3 p-1"
        />
        <customGaugeChart
          title="Taux de remplissage composants techniques"
          units=""
          gaugeType="percentage"
          :value="55"
          class="col-md-3 p-1"
        />
        <customPieChart
          :options="TauxDeRefcardParStatusOptions"
          title="Taux de refcard par statut"
          class="col-md-3 p-1"
        />
      </div>
    </div>
  </section>
</template>

<script>
import {
  ForceImpactPatrimoineOptions,
  typeImpactObjectOptions,
  ApplicationsEligibilesClaudificationOptions,
  FluxApplicatifsOptions,
  TauxDeRefcardParStatusOptions,
} from "../chartsData.js";

import AnalyticsStatistics from "../components/AnalyticsStatistics.vue";
import customBarChart from "../components/customBarChart.vue";
import customPieChart from "../components/customPieChart.vue";
import ListButtonActions from "../components/ListButtonActions.vue";
import customGaugeChart from "../components/customGaugeChart.vue";

export default {
  components: {
    customBarChart,
    customPieChart,
    AnalyticsStatistics,
    ListButtonActions,
    customGaugeChart,
  },
  data() {
    return {
      firstSection: [
        {
          title: "Applications migrées sur le cloud",
          icon: "mdi-apple-icloud",
          value: 19,
          color: " rgb(116, 208, 241)",
          link: "/transformation/initiative",
        },
        {
          title: "Applications On Premise",
          icon: "mdi-cogs",
          value: 90,
          color: "rgb(242, 95, 10)",
          link: "/transformation/deliverable",
        },
        // ["Equipes", "mdi-account-group", 6, "rgb(138, 10, 242)"],
        {
          title: "Applications hébergées à l'exterieur",
          icon: "mdi-chevron-up ",
          value: 12,
          color: "rgb(68, 110, 155)",
          link: "/strategy/goals",
        },
        {
          title: "Taux d'ouverture SI",
          icon: "mdi-swap-horizontal ",
          value: "37.8%",
          color: "rgb(255 156 112)",
          link: "/transformation/fdr",
        },
        {
          title: "Capacité d'ouverture du SI",
          icon: "mdi-open-in-new ",
          value: " 78.1%",
          color: "rgb(151, 223, 198)",
          link: "/transformation/fdr",
        },
      ],
      ForceImpactPatrimoineOptions,
      typeImpactObjectOptions,
      ApplicationsEligibilesClaudificationOptions,
      FluxApplicatifsOptions,
      TauxDeRefcardParStatusOptions,
    };
  },
};
</script>

<style scoped>
.custom-icon-size {
  font-size: 4rem;
}
.custom-title-size {
  font-size: 0.8rem;
}

.custom-icon-size {
  font-size: 4rem;
}
.custom-title-size {
  font-size: 0.8rem;
}

</style>